var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"recover"},[_c('h1',[_vm._v("비밀번호 찾기")]),_c('p',[_vm._v(" 비밀번호를 찾기 위해 아이디와 가입 시 등록한 이메일주소를 입력해주세요. 가입 시 입력하신 이메일로 새 비밀번호를 발송해 드립니다. ")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var untouched = ref.untouched;
return [_c('form',{on:{"submit":_vm.onSubmit,"reset":_vm.onReset}},[_c('ValidationProvider',{attrs:{"name":"아이디","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.loginid),expression:"form.loginid"}],class:{ active: errors[0] },attrs:{"type":"text","autocapitalize":"off","autocomplete":"off","placeholder":"아이디","disabled":_vm.submitStatus === 'PENDING'},domProps:{"value":(_vm.form.loginid)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "loginid", $event.target.value)}}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"이메일 주소","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],class:{ active: errors[0] },attrs:{"type":"email","autocapitalize":"off","autocomplete":"off","placeholder":"이메일 주소","disabled":_vm.submitStatus === 'PENDING'},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('div',{staticClass:"btn_100"},[_c('base-button',{attrs:{"type":"primary-lg","nativeType":"submit","disabled":invalid || _vm.submitStatus === 'PENDING' || untouched}},[_vm._v(_vm._s(_vm.submitStatus === "PENDING" ? "메일 발송중.." : "비밀번호 찾기"))])],1)],1)]}}])}),_c('div',{staticClass:"login_reg"},[_c('p',{on:{"click":function($event){return _vm.confirm('로그인')}}},[_vm._v("로그인")]),_c('span',[_vm._v("|")]),_c('p',{on:{"click":function($event){return _vm.confirm('회원가입')}}},[_vm._v("회원가입")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }