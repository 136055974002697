<template>
  <main class="recover">
    <h1>비밀번호 찾기</h1>
    <p>
      비밀번호를 찾기 위해 아이디와 가입 시 등록한 이메일주소를 입력해주세요.
      가입 시 입력하신 이메일로 새 비밀번호를 발송해 드립니다.
    </p>
    <ValidationObserver v-slot="{ invalid, untouched }">
      <form @submit="onSubmit" @reset="onReset">
        <ValidationProvider name="아이디" rules="required" v-slot="{ errors }">
          <input
            type="text"
            v-model="form.loginid"
            autocapitalize="off"
            autocomplete="off"
            placeholder="아이디"
            :disabled="submitStatus === 'PENDING'"
            :class="{ active: errors[0] }"
          />
          <span>{{ errors[0] }}</span>
        </ValidationProvider>
        <ValidationProvider
          name="이메일 주소"
          rules="required"
          v-slot="{ errors }"
        >
          <input
            type="email"
            v-model="form.email"
            autocapitalize="off"
            autocomplete="off"
            placeholder="이메일 주소"
            :disabled="submitStatus === 'PENDING'"
            :class="{ active: errors[0] }"
          />
          <span>{{ errors[0] }}</span>
        </ValidationProvider>
        <div class="btn_100">
          <base-button
            type="primary-lg"
            nativeType="submit"
            :disabled="invalid || submitStatus === 'PENDING' || untouched"
            >{{
              submitStatus === "PENDING" ? "메일 발송중.." : "비밀번호 찾기"
            }}</base-button
          >
        </div>
      </form>
    </ValidationObserver>
    <div class="login_reg">
      <p @click="confirm('로그인')">로그인</p>
      <span>|</span>
      <!-- <router-link to="">아이디 찾기</router-link><span>|</span> -->
      <p @click="confirm('회원가입')">회원가입</p>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import BaseButton from "@/components/core/BaseButton.vue";
import Dialog from "@/components/Dialog.vue";
import client from "api-client";

export default {
  name: "Login",
  components: {
    BaseButton,
  },
  data() {
    return {
      submitStatus: null,
      form: {
        loginid: "",
        email: "",
      },
    };
  },
  created() {
    this.$Progress.start();
  },
  mounted() {
    this.$Progress.finish();
    this.submitStatus = null;
  },
  computed: {
    ...mapGetters(["isAuthenticated", "getSaveLoginid"]),
  },
  destroyed() {
    /**
     * destroyed
     */
  },
  methods: {
    confirm(type) {
      if (this.form.loginid != "" || this.form.email != "") {
        this.$modal.show(
          Dialog,
          {
            type: "confirm",
            title: "알림",
            content: `비밀번호 찾기 하지 않고 ${type} 페이지로 이동 하시겠습니까?`,
          },
          {
            adaptive: true,
            width: "90%",
            maxWidth: 600,
            height: "auto",
          },
          {
            "before-close": (response) => {
              if (response.params.confirm) {
                if (type == "로그인") {
                  this.$router.push({ name: "Login" });
                } else if (type == "회원가입") {
                  this.$router.push({ name: "Register" });
                }
              }
            },
          }
        );
      } else {
        if (type == "로그인") {
          this.$router.push({ name: "Login" });
        } else if (type == "회원가입") {
          this.$router.push({ name: "Register" });
        }
      }
    },
    async onSubmit(evt) {
      evt.preventDefault();
      this.submitStatus = "PENDING";

      let params = {
        email: this.form.email,
        loginid: this.form.loginid,
      };
      await client.authRecover(params).then(
        (response) => {
          this.submitStatus = "OK";
          if (response.status == 204) {
            this.$modal.show(
              Dialog,
              {
                title: "알림",
                content: "이메일이 정상적으로 발송되었습니다.",
              },
              {
                adaptive: true,
                width: "90%",
                maxWidth: 600,
                minWidth: 300,
                height: "auto",
              },
              {
                "before-open": () => {},
                "before-close": () => {},
                closed: () => {
                  this.$router.push({ name: "Login" });
                },
              }
            );
          }
        },
        (error) => {
          this.submitStatus = "ERROR";
          const { data } = error.response;
          this.$modal.show(
            Dialog,
            {
              title: "알림",
              content: data.message,
            },
            {
              adaptive: true,
              width: "90%",
              maxWidth: 600,
              minWidth: 300,
              height: "auto",
            },
            {
              "before-open": () => {},
              "before-close": () => {},
            }
          );
        }
      );
    },
    // 취소
    onReset(evt) {
      evt.preventDefault();
      /*
      this.$router.push({
        name: "AuthTerms"
      });
      */
    },
  },
};
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
